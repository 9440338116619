export default {
  transactionBatchPurchaseApprove: require('./transactionBatchPurchaseApprove')
    .default,
  transactionBatchPurchaseDisapprove: require('./transactionBatchPurchaseDisapprove')
    .default,
  transactionCodeFreeGoodApprove: require('./transactionCodeFreeGoodApprove')
    .default,
  transactionBatchFreeGoodFLLApprove: require('./transactionBatchFreeGoodFLLApprove')
    .default,
  transactionBatchFreeGoodFLLDisapprove: require('./transactionBatchFreeGoodFLLDisapprove')
    .default,
  userActivateDeactivate: require('./userActivateDeactivate.tsx').default,
  updateCustomerDetails: require('./updateCustomerDetails.tsx').default,
  updateUserDetails: require('./updateUserDetails.tsx').default,
  voidTransaction: require('./voidTransaction.tsx').default,
  extendTransactionCode: require('./extendTransactionCode.tsx').default,
  carryOverTransaction: require('./carryOverTransaction.tsx').default,
  addProgram: require('./addProgram').default,
  logout: require('./logout').default,
  settings: require('./settings').default,
  hkapiReportExport: require('./hkapiReportExport').default,
  BpDeliveryButton: require('./sanofi-my/bpDelivery').default,
  optoutButton: require('./sanofi-my/optoutButton').default,
  exportTable: require('./exportTable').default,
  updateLineItem: require('./updateLineItem.jsx').default,
  updateOverallPatientDetails: require('./updateOverallPatientDetails.jsx').default,
  chasCardApproval: require('./chasCardApproval.jsx').default,
  addHCP: require('./addHCP').default,
  extendTransactionCode: require('./extendTransactionCode.tsx').default,
  sendPrefilledLink:  require('./sendPrefilledLink.tsx').default,
  updateFreeGoodDeliveryStatus:  require('./updateFreeGoodDeliveryStatus').default,
  updateManualForm: require('./uploadManualForm').default
};
