import apiClient from '../utilities/apiClient.utility';

const transactionsUrl = `${window.config.CONNECT_URLS.transactions}`;

export const postTransactionBatchPurchaseApprove = async ({
  transaction_batch_id,
  connect_program_id,
  user_id,
}) => {
  const result = await apiClient.post(
    `${transactionsUrl}/${connect_program_id}/dashboard/transaction-batch/${transaction_batch_id}/approve`,
    { user_id: user_id }
  );
  return result;
};

export const putTransactionCodeFreeGoodApprove = async ({
  transaction_code_id,
  connect_program_id,
}) => {
  const result = await apiClient.put(
    `${transactionsUrl}/${connect_program_id}/dashboard/transaction-code/${transaction_code_id}/approve`,
    null
  );
  return result;
};

export const postTransactionBatchPurchaseDisapprove = async ({
  transaction_batch_id,
  connect_program_id,
  user_id,
}) => {
  return await apiClient.post(
    `${transactionsUrl}/${connect_program_id}/dashboard/transaction-batch/${transaction_batch_id}/disapprove`,
    { user_id: user_id }
  );
};

export const freeGoodTransactionManualUpdate = async ({
  connect_program_id,
  transaction_batch_id,
  action,
  payload,
}) => {
  const result = await apiClient.post(
    `${transactionsUrl}/${connect_program_id}/dashboard/transaction-batch/${transaction_batch_id}/free-good/${action}`,
    payload
  );
  return result;
};

export const freeGoodTransactionConfirmation = async ({
  connect_program_id,
  transaction_code_id,
  action,
  payload,
}) => {
  const result = await apiClient.put(
    `${transactionsUrl}/${connect_program_id}/dashboard/transaction-code/${transaction_code_id}/${action}`,
    payload
  );
  // transaction-code/28487/free-good/update
  return result;
};

export const extendPatientTransactionCode = async ({
  program_id,
  transaction_code_id,
}) => {
  const result = await apiClient.put(
    `${transactionsUrl}/${program_id}/dashboard/transaction-code/${transaction_code_id}/extend`,
    {}
  );
  return result;
};

export const voidTransactionBatch = async ({
  program_id,
  transaction_batch_id,
}) => {
  const result = await apiClient.post(
    `${transactionsUrl}/${program_id}/dashboard/transaction-batch/${transaction_batch_id}/void`,
    {}
  );
  return result;
};

export const voidTransactionBatchId = async ({
  program_id,
  transaction_batch_id,
}) => {
  const result = await apiClient.post(
    `${transactionsUrl}/${program_id}/voidtransaction`,
    { transaction_id: transaction_batch_id }
  );
  return result;
};

export const voidTransactionBatchStellar = async ({
  program_id,
  transaction_batch_id,
}) => {
  const result = await apiClient.delStellar(`${transactionsUrl}`, {
    transaction_batch_id,
    isCustomerSupport: true,
  });
  return result;
};

export const updateTransactionLineItem = async ({
  connect_program_id,
  transaction_line_item_id,
  payload,
}) => {
  const result = await apiClient.put(
    `${transactionsUrl}/${connect_program_id}/dashboard/transaction-line-item/${transaction_line_item_id}/update`,
    payload
  );
  return result;
};
