import apiClient from '../utilities/apiClient.utility';

const customerUrl = `${window.config.CONNECT_URLS.customers}`;

export const carryOverPatientTransaction = async ({ program_id, customer_id }) => {
    return await apiClient.post(
      `${customerUrl}/${program_id}/dashboard/${customer_id}/carry-over`,
      {}
    );
};

export const updateCustomerInfo = async ({ program_id, customer_id, payload }) => { 
  return await apiClient.put(
    `${customerUrl}/${program_id}/dashboard/${customer_id}`, payload
  );
};

export const partialUpdateCustomerInfo = async ({ program_id, customer_id, payload }) => { 
  return await apiClient.patch(
    `${customerUrl}/${program_id}/dashboard/${customer_id}`, payload
  );
};

export const manuallyCreatePatient = async ({ program_id, payload }) => {
  return await apiClient.post(
    `${customerUrl}/${program_id}/dashboard/manual-pre-enrollment`, payload
  );
}

export const sendPatientTnc = async ({ program_id, customer_id }) => {
  return await apiClient.post(
    `${customerUrl}/${program_id}/dashboard/${customer_id}/send-tnc-link`
  );
}
