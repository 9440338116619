import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import mustache from 'mustache';
import stores from '../../models/index.model';
import queryString from 'query-string';
import { Grid, Button, Typography } from '@material-ui/core';
import 'react-table/react-table.css';
import './PaginatedTable.style.scss';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { withTheme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import Carousel from '../Carousel/Carousel.component';
import { DataCard } from '../Card/Card.component';
import FreeGoodManualAction from '../ActionButtons/transactionBatchFreeGoodManualAction';
import UpdateOverallPatientDetails from '../ActionButtons/updateOverallPatientDetails';
import ChasCardApproval from '../ActionButtons/chasCardApproval';
import ExportTableAction from '../ActionButtons/exportTable';
import AddHCPAction from '../ActionButtons/addHCP';
import SendPrefilledLink from '../ActionButtons/sendPrefilledLink';
import UploadManualForm from '../ActionButtons/uploadManualForm';

// I cant import
import TransactionCodeFreeGoodApproveButton from '../ActionButtons/updateFreeGoodDeliveryStatus.jsx';
import BpDeliveryButton from '../ActionButtons/sanofi-my/bpDelivery.jsx';
import OptOutButton from '../ActionButtons/sanofi-my/optoutButton.jsx';
import BlacklistButton from '../ActionButtons/sanofi-my/blacklistButton.jsx';
import TransactionBatchPurchaseApprove from '../../components/ActionButtons/transactionBatchPurchaseApprove.jsx';
import TransactionBatchPurchaseDisapprove from '../../components/ActionButtons/transactionBatchPurchaseDisapprove.jsx';
import {
  TRANSACTIONS_CONSTANT,
  PROGRAMS_CONSTANT,
  REPORTING_CONSTANT,
} from '../../constants/common';
import { remove, values, includes, isEmpty, startsWith } from 'lodash';
import UpdateTransactionButton from '../ActionButtons/sanofi-my/updateTransactionButton';
import VoidTransactionButton from '../ActionButtons/common-buttons/voidTransactionButton';
import UpdateLineItem from '../ActionButtons/updateLineItem';
import FreeGoodConfirmation from '../ActionButtons/transactionBatchFreeGoodConfirmation';
import AddPatientAction from '../ActionButtons/addPatient';
import SendPatientTermsConditionsAction from '../ActionButtons/sendPatientTermsConditions';

class ReactSimpleTable extends React.Component {
  state = {
    columns: [],
  };

  constructor(props) {
    // See: https ://reactjs.org/docs/legacy-context.html
    super(props);

    this._handleClickBlackList = this._handleClickBlackList.bind(this);
    this._handleClickBpDeliveryYes = this._handleClickBpDeliveryYes.bind(this);
    this._handleClickBpDeliveryNo = this._handleClickBpDeliveryNo.bind(this);
    this._handleClickOptOut = this._handleClickOptOut.bind(this);
    this._handleUpdateTransactionBatch = this._handleUpdateTransactionBatch.bind(
      this
    );
    this._handleVoidTransactionBatch = this._handleVoidTransactionBatch.bind(
      this
    );
  }

  componentDidMount() {
    let columnsTemp = null;

    const { columns, match, data } = this.props;
    const { connect_program_id: program_id, page_key } = match.params;
    const medkakiProgramIDList = values(PROGRAMS_CONSTANT.PROGRAM_ID.MEDKAKI);
    const isMedkaki = includes(medkakiProgramIDList, program_id);

    if (
      !isEmpty(data) &&
      data[0].transaction_type ===
        TRANSACTIONS_CONSTANT.TRANSACTION_TYPE.REDEEM &&
      isMedkaki &&
      page_key === REPORTING_CONSTANT.PAGE_KEY.TRANSACTION_DETAIL
    ) {
      remove(columns, { accessor: 'free_good_quantity' });
    }

    columnsTemp = rewriteSpecialColumns({
      columns,
      // propsColumns,
      program_id,
      onClickBlackList: this._handleClickBlackList,
      onClickBpDeliveryYes: this._handleClickBpDeliveryYes,
      onClickBpDeliveryNo: this._handleClickBpDeliveryNo,
      onClickOptOut: this._handleClickOptOut,
      onUpdateTransactionBatch: this._handleUpdateTransactionBatch,
      onVoidTransactionBatch: this._handleVoidTransactionBatch,
      imBtnVisibility: this.props.btnVisiblity,
    });
    // SET universal minWidth and maxWidth for columns
    this.setState({ columns: columnsTemp });
  }

  _replaceText(text, params) {
    return mustache.render(text, params);
  }

  /**
   * On click black list action button event handler.
   *
   * @param {ID} userId The user id to be black listed.
   * @returns {Void}
   */
  async _handleClickBlackList({ user_id }) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      await onChange({ user_id }, 'blacklist');
    }
  }

  /**
   * On click bp delivered action button event handler.
   *
   * @param {ID} userId The user id to be marked as bp delivered.
   * @returns {Void}
   */
  async _handleClickBpDeliveryYes({ user_id }) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      await onChange({ user_id }, 'bpDeliveryDelivered');
    }
  }

  /**
   * On click bp not delivered action button event handler.
   *
   * @param {ID} userId The user id to be marked as bp not delivered.
   * @returns {Void}
   */
  async _handleClickBpDeliveryNo({ user_id }) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      await onChange({ user_id }, 'bpDeliveryNotDelivered');
    }
  }

  /**
   * On click opt out action button event handler.
   *
   * @param {ID} userId The user id to be opted out.
   * @returns {Void}
   */
  async _handleClickOptOut({ user_id }) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      await onChange({ user_id }, 'optOut');
    }
  }

  /**
   * On update transaction batch event handler.
   *
   * @returns {Void}
   */
  async _handleUpdateTransactionBatch() {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      await onChange({}, '');
    }
  }

  async _handleVoidTransactionBatch() {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      await onChange({}, '');
    }
  }

  pagination = () => {
    const params = queryString.parse(this.props.history.location.search);
    let currentPage = this.props.currentPage || params.page;
    if (
      currentPage < 1 ||
      currentPage > this.props.totalPages ||
      isNaN(currentPage)
    )
      currentPage = 1;

    return (
      <DataCard
        style={{ background: '#8b1b3f', height: '100%' }}
        // onExport={() => this._openExportModal()}
        // dataCount={this.props.patientStore.approvedPatients.count}
      >
        <Grid container spacing={3}>
          <Grid item xs={4} container justify="flex-end">
            <Grid item xs={6}>
              <Button
                fullWidth
                size="large"
                color="primary"
                variant="contained"
                onClick={this._onPrevious}
                disabled={(currentPage || 1) <= 1 ? true : false}
              >
                <KeyboardArrowLeft style={{ marginRight: 6 }} />
                Previous
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div>
              Page {currentPage || 1} of {this.props.totalPages}
            </div>
          </Grid>
          <Grid item xs={4} container justify="flex-start">
            <Grid item xs={6}>
              <Button
                fullWidth
                size="large"
                color="primary"
                variant="contained"
                onClick={this._onNext}
                disabled={
                  (currentPage || 1) >= this.props.totalPages ? true : false
                }
              >
                Next
                <KeyboardArrowRight style={{ marginLeft: 6 }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DataCard>
    );
  };

  _onNext = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pageIdentifier } = this.props;
    const params = queryString.parse(this.props.history.location.search);
    params.start_date && delete params.start_date;
    params.end_date && delete params.end_date;
    let currentPage =
      this.props.currentPage || params[pageIdentifier || 'page'];
    pageIdentifier && delete params[pageIdentifier];
    params.page && delete params.page;
    const stringParams = queryString.stringify(params);
    if (
      currentPage < 1 ||
      currentPage > this.props.totalPages ||
      isNaN(currentPage)
    )
      currentPage = 1;
    const page = parseInt(currentPage || 1, 10) + 1;
    this.props.history.push(
      `${this.props.history.location.pathname}?${stringParams}&${
        pageIdentifier || 'page'
      }=${page}&start_date=${start_date}&end_date=${end_date}`
    );
  };

  _onPrevious = async () => {
    const { start_date, end_date } = this.props.filterStore.filters;
    const { pageIdentifier } = this.props;
    const params = queryString.parse(this.props.history.location.search);
    params.start_date && delete params.start_date;
    params.end_date && delete params.end_date;
    let currentPage =
      this.props.currentPage || params[pageIdentifier || 'page'];
    pageIdentifier && delete params[pageIdentifier];
    params.page && delete params.page;
    const stringParams = queryString.stringify(params);
    if (
      currentPage < 1 ||
      currentPage > this.props.totalPages ||
      isNaN(currentPage)
    )
      currentPage = 1;
    const page = parseInt(currentPage || 1, 10) - 1;
    this.props.history.push(
      `${this.props.history.location.pathname}?${stringParams}&${
        pageIdentifier || 'page'
      }=${page}&start_date=${start_date}&end_date=${end_date}`
    );
  };

  render() {
    const {
      data = [],
      history,
      match,
      title,
      exportButton = false,
      addHCPButton = false,
      addPatientButton = false
    } = this.props;
    const { columns } = this.state;
    // const { palette } = this.props.theme;
    let dateFilter = '';
    const params = queryString.parse(this.props.history.location.search);
    const start_date = params.start_date;
    const end_date = params.end_date;
    if (params && start_date && end_date) {
      dateFilter = `&start_date=${start_date}&end_date=${end_date}`;
    }
    return (
      <DataCard title={title}>
        {
          addHCPButton && (
            <AddHCPAction data={{ ...this.props, start_date, end_date }}></AddHCPAction>
          )
        }
        {
          addPatientButton && (
            <AddPatientAction data={{ ...this.props, start_date, end_date }}></AddPatientAction>
          )
        }
        {exportButton && (
          <ExportTableAction data={{ ...this.props, start_date, end_date }} />
        )}
        <ReactTable
          data={data}
          columns={columns}
          filterable={false}
          sortable={false}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]).match(new RegExp(filter.value, 'i'))
          }
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  opacity: rowInfo.original.deleted_at ? '0.4' : '1',
                  background: rowInfo.original.deleted_at ? 'gray' : 'white',
                },
              };
            } else {
              return {};
            }
          }}
          getTdProps={(state, rowInfo, column) => {
            return rowInfo?.original
              ? {
                  onClick: (e) => {
                    if (column.link) {
                      history.push(
                        '/' +
                          match.params.connect_program_id +
                          this._replaceText(column.link, rowInfo.original) +
                          dateFilter
                      );
                    }
                  },
                  style: column.link && {
                    // color: palette.primary.main,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  },
                }
              : {};
          }}
          getTheadProps={() => {
            return {
              style: {
                backgroundColor:
                  window.programConfig.program_metadata.page_theme
                    ?.primary_color || '#d82819',
              },
            };
          }}
          defaultPageSize={data.length > 0 ? 10 : 0}
          NoDataComponent={() => {
            return (
              <Typography
                variant="h6"
                style={{ padding: '20px' }}
                align="center"
              >
                No rows found
              </Typography>
            );
          }}
          showPagination={!!data.length}
          showPageSizeOptions={false}
          // PaginationComponent={this.pagination}
          className="-highlight card"
        />
      </DataCard>
    );
  }
}

// REMATCH

export default withTheme(ReactSimpleTable);

const _getVaccineQuantity = (data) => {
  try {
    const receiptData = JSON.parse(data);
    const result = receiptData
      .map((data) => data.number_of_vaccines ?? 1)
      .reduce((acc, curr) => acc + curr, 0);

    return isNaN(result) ? 'N/A' : result;
  } catch (e) {
    return 'Error';
  }
};

const _getImageUrl = (data) => {
  let imageUrl = data;
  let isImageArray;
  let imageDetails;

  try {
    imageUrl = JSON.parse(data);
  } catch (e) {}

  if (imageUrl && Array.isArray(imageUrl) && imageUrl.length) {
    try {
      imageDetails = imageUrl.map((imgDetails) => {
        const urlPattern = /([\w\d_-]*)\.?[^\\\/]*$/i;
        let trackingId = null;
        if (imgDetails.image_url) {
          trackingId = imgDetails.image_url.match(urlPattern)[1];
        }
        if (imgDetails.webview_path) {
          trackingId = imgDetails.webview_path.match(urlPattern)[1];
        }

        return {
          dateUploaded: imgDetails.uploaded_date
            ? moment(imgDetails.uploaded_date).format('MM/DD/YYYY HH:mm')
            : null,
          trackingId,
        };
      });

      if (imageUrl[0].image_url) {
        imageUrl = imageUrl.map((image) => image.image_url);
      }
      if (imageUrl[0].webview_path) {
        imageUrl = imageUrl.map((image) => image.webview_path);
      }
    } catch (e) {
      console.log('Not an array of objects with image_url');
    }
    const isMultipleImages = imageUrl.length > 1;
    isImageArray = isMultipleImages;
    imageUrl = isMultipleImages ? imageUrl : imageUrl[0];
  } else if (Array.isArray(imageUrl) && imageUrl.length <= 0) {
    imageUrl = null;
  } else {
    isImageArray = false;
  }

  return { imageUrl, isImageArray, imageDetails };
};

/**
 * Create new and modified column properties based on `col_type`.
 *
 * @param {Object} param0 { columns, program_id, onClickBlackList, onClickBpDeliveryYes, onClickBpDeliveryNo, onClickOpOut }
 * @returns []
 */
function rewriteSpecialColumns({
  columns = [],
  program_id,
  onClickBlackList,
  onClickBpDeliveryYes,
  onClickBpDeliveryNo,
  onClickOptOut,
  onUpdateTransactionBatch,
  onVoidTransactionBatch,
  imBtnVisibility,
}) {
  let modifiedColumns = [];
  // Clone, prevent args mutations.
  const columnsCopy = [...columns];

  columnsCopy.forEach((column) => {
    if (column.col_type) {
      switch (column.col_type) {
        // VACCINE QUANTITY (for FLL program)
        case 'vaccine_quantity':
          column['Cell'] = (props) => {
            const data = props.original[column.accessor];
            return _getVaccineQuantity(data);
          };
          break;
        // IMAGE column type
        case 'image':
          column['Cell'] = (props) => {
            const data = props.original[column.accessor];
            const {
              imageUrl: imageURL,
              isImageArray,
              imageDetails,
            } = _getImageUrl(data);

            return imageURL ? (
              <Button
                disabled={imBtnVisibility}
                color="secondary"
                variant="contained"
                size="small"
                onClick={() =>
                  stores.dispatch.uiStore.showPopupModal({
                    title: props.column.Header && props.column.Header,
                    message: (
                      <div>
                        {isImageArray ? (
                          <div style={{ textAlign: 'center' }}>
                            <Carousel
                              images={imageURL}
                              imageDetails={imageDetails}
                            />
                            <span style={{ display: 'inline-block' }}>
                              Click particular image to enlarge
                            </span>
                          </div>
                        ) : (
                          <img
                            style={{
                              objectFit: 'contain',
                              height: '240px',
                              width: '480px',
                              margin: '0 auto',
                              display: 'block',
                            }}
                            src={imageURL}
                          />
                        )}
                      </div>
                    ),
                    actions: !isImageArray
                      ? [
                          {
                            label: 'Full View',
                            onClick: () => {
                              const link = document.createElement('a');
                              link.href = imageURL;
                              link.download = `${imageURL.split('/').pop()}`;
                              link.target = '_blank';
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            },
                          },
                        ]
                      : [],
                  })
                }
              >
                View Image
              </Button>
            ) : (
              '--'
            );
          };
          break;
        // DATE column type
        case 'date':
          column['Cell'] = (props) => {
            const date = props.original[column.accessor];

            return date ? moment(date).format('MM/DD/YYYY HH:mm') : '--';
          };
          break;
        case 'date_no_time':
          column['Cell'] = (props) => {
            const date = props.original[column.accessor];
            return date ? moment(date).format('MM/DD/YYYY') : '--';
          };
          break;
        // PARAGRAPH column type
        case 'paragraph':
          column['Cell'] = (props) => {
            const paragraph = props.original[column.accessor];
            return paragraph ? (
              <b
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  stores.dispatch.uiStore.showPopupModal({
                    title: props.column.Header && props.column.Header,
                    message: <span>{props.original[column.accessor]}</span>,
                    actions: [],
                  })
                }
              >
                {props.original[column.accessor]}
              </b>
            ) : (
              '--'
            );
          };
          break;
        // MULTILINE column type
        case 'multiline':
          column['Cell'] = (props) => (
            <span>
              {props.original[column.accessor] &&
                props.original[column.accessor].split(',').map((value) => {
                  return <div key={value}>{value}</div>;
                })}
            </span>
          );
          break;
        case 'vaxx_array':
          column['Cell'] = (props) => {
            const vaxxDateArray = JSON.parse(props.original[column.accessor]);
            const applicationType = props.original['patient_type'];
            return (
              <span>
                {vaxxDateArray &&
                  vaxxDateArray.map((value) => {
                    return (
                      <div key={value.nth_dose}>
                        {applicationType === 'baby_vaccination'
                          ? `${toOrdinal(value.nth_dose)} dose:`
                          : ''}
                        {value.date
                          ? ` ${moment(value.date).format('YYYY-MM-DD')}`
                          : ' -- '}{' '}
                      </div>
                    );
                  })}
              </span>
            );
          };
          break;
        // USER_ID column type
        case 'shorten_id':
        case 'user_id':
          column['Cell'] = (props) => {
            const value = props.original[column.accessor];
            if (startsWith(value, 'MYCARE')) {
              const match = value.match(/MYCARE[0]{0,}/);
              return value.split(match[0]);
            }

            return value.substring(0, 5);
          };
          break;
        case 'action':
          column['Cell'] = (props) => {
            const value = props.original[column.accessor];
            return (
              <TransactionCodeFreeGoodApproveButton
                data={{ transaction_code_id: value }}
              />
            );
          };
          break;
        case 'delivery_action':
            column['Cell'] = (props) => {
              const value = props.original[column.accessor];
              return (
                <TransactionCodeFreeGoodApproveButton
                  data={{ transaction_code_id: value }}
                />
              );
            };
            break;
        case 'bp_action':
          column['Cell'] = (props) => {
            const value = props.original[column.accessor];
            return (
              <BpDeliveryButton
                data={{ user_id: value }}
                onClickYes={onClickBpDeliveryYes}
                onClickNo={onClickBpDeliveryNo}
              />
            );
          };
          break;
        case 'transaction_approve_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const value = data[column.accessor];
            const transaction_status = data.transaction_status;
            const transaction_type = data.transaction_type;
            return (
              <TransactionBatchPurchaseApprove
                data={{
                  transaction_batch_id: value,
                  user_id: props.original['patient_id'],
                  transaction_status,
                  transaction_type,
                }}
              />
            );
          };
          break;
        case 'transaction_disapprove_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const value = data[column.accessor];
            const transaction_status = data.transaction_status;
            const transaction_type = data.transaction_type;

            return (
              <TransactionBatchPurchaseDisapprove
                data={{
                  transaction_batch_id: value,
                  user_id: props.original['patient_id'],
                  transaction_status,
                  transaction_type,
                }}
              />
            );
          };
          break;
        case 'optout_action':
          column['Cell'] = (props) => {
            const value = props.original[column.accessor];
            return (
              <OptOutButton data={{ user_id: value }} onClick={onClickOptOut} />
            );
          };
          break;
        case 'blacklist_action':
          column['Cell'] = (props) => {
            const value = props.original[column.accessor];
            return (
              <BlacklistButton
                data={{ user_id: value }}
                onClick={onClickBlackList}
              />
            );
          };
          break;
        case 'product_sku':
          column['Cell'] = (props) => {
            const productSkuId = props.original[column.accessor]
              ? props.original[column.accessor]
              : '--';
            const productSkuItems = productSkuId && productSkuId.split('_');
            const productSkuLabelFormatted =
              productSkuItems &&
              Array.isArray(productSkuItems) &&
              productSkuItems.length === 3
                ? `${
                    productSkuItems[0].charAt(0).toUpperCase() +
                    productSkuItems[0].slice(1)
                  } ${productSkuItems[1]}/${productSkuItems[2]}`
                : productSkuId;
            return productSkuLabelFormatted;
          };
          break;
        case 'free_good_manual_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const transaction_batch_id = data.transaction_batch_id;
            const quantity = data.qty_unclaimed;
            const patient_id = data.patient_id;

            return (
              <FreeGoodManualAction
                data={{
                  transaction_batch_id,
                  quantity,
                  patient_id,
                  product_sku_id: data.sku_id,
                  program_id: program_id,
                }}
              ></FreeGoodManualAction>
            );
          };
          break;
          case 'free_good_confirmation_action':
            column['Cell'] = (props) => {
              const data = props.original;
              const transaction_code_id = data.transaction_code_id;
              const quantity = data.qty_unclaimed;
              const patient_id = data.patient_id;
              const patient_name = data.patient_name;
  
              return (
                <FreeGoodConfirmation
                  data={{
                    transaction_code_id,
                    quantity,
                    patient_id,
                    program_id: program_id,
                    patient_name
                  }}
                ></FreeGoodConfirmation>
              );
            };
            break;
        case 'send_tnc_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const patient_id = data.patient_id;
            const status = data.status;
            const tnc_sent = data.tnc_sent;

            if (status === 'registered') {
              return (
                <SendPatientTermsConditionsAction
                  data={{
                    patient_id,
                    program_id: program_id,
                  }}
                ></SendPatientTermsConditionsAction>
              );
            }
            return <></>;
          };
          break;
        case 'update_transaction_batch_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const transaction_batch_id = data[column.accessor];
            const transaction_status = data.transaction_status;
            const transaction_type = data.transaction_type;
            const user_id = props.original['patient_id'];

            return (
              <UpdateTransactionButton
                data={{
                  transaction_batch_id,
                  user_id,
                  transaction_status,
                  transaction_type,
                }}
                onUpdate={onUpdateTransactionBatch}
              />
            );
          };
          break;
        case 'update_line_item_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const transaction_line_item_id = data.transaction_line_item_id;
            const meditag_number = data.meditags_used;
            const meditag_image_link = data.meditag_image;
            const patient_id = data.patient_id;

            return (
              <UpdateLineItem
                data={{
                  transaction_line_item_id,
                  meditag_number,
                  meditag_image_link,
                  program_id: program_id,
                  patient_id,
                }}
              ></UpdateLineItem>
            );
          };
          break;
        case 'age':
          column['Cell'] = (props) => {
            const data = props.original[column.accessor];
            const monthDiff = moment().diff(data, 'months');
            if (monthDiff >= 12) {
              return `${Math.floor(monthDiff / 12)} year(s) ${
                monthDiff % 12 !== 0 ? `${monthDiff % 12} month(s)` : ''
              }`;
            }
            return `${monthDiff} month(s)`;
          };
          break;
        case 'void_transaction_batch_action':
          column['Cell'] = (props) => {
            const data = props.original;
            const transaction_batch_id = data[column.accessor];

            return (
              <VoidTransactionButton
                data={{
                  transaction_batch_id,
                  program_id: program_id,
                }}
                onVoid={onVoidTransactionBatch}
              />
            );
          };
          break;
          case 'overall_patient_manual_action':
            column['Cell'] = (props) => {
              const data = props.original;
              const { chasis_card_status, chasis_card_url, email_approval_img, user_tier, patient_id } = data;

              return (
                <UpdateOverallPatientDetails
                  data={{
                    program_id: program_id,
                    chasis_card_status, chasis_card_url, email_approval_img, user_tier, patient_id
                  }}
                ></UpdateOverallPatientDetails>
              );
            };
            break;
          case 'overall_patient_chas_approval_action':
            column['Cell'] = (props) => {
              const data = props.original;
              const { chasis_card_status, chasis_card_url, email_approval_img, user_tier, patient_id } = data;

              return (
                <ChasCardApproval
                  data={{
                    program_id: program_id,
                    chasis_card_status, chasis_card_url, email_approval_img, user_tier, patient_id
                  }}
                ></ChasCardApproval>
              );
            };
          break;
          case 'send_prefilled_link':
          column['Cell'] = (props) => {
            const data = props.original;
            const { email, hcp_name, hcp_id, user_type  } = data;

            return (
              <SendPrefilledLink
                data={{
                  email, hcp_name, hcp_id, user_type 
                }}
              />
            );
          };
          case 'upload_manual_form_action':
            column['Cell'] = (props) => {
              const data = props.original;
              const { patient_id, manual_form, program_id  } = data;
  
              return (
                <UploadManualForm
                  data={{
                    patient_id, manual_form, program_id
                  }}
                />
              );
            };
        default:
          break;
      }
    } else {
      //ADD -- IF VALUE IS NULL
      column['Cell'] = (props) => {
        const value = props.original[column.accessor];
        return value === null ? '--' : value;
      };
    }

    modifiedColumns.push({ ...column });
  });

  return modifiedColumns;
}

function toOrdinal(number) {
  return `${number}${
    ['st', 'nd', 'rd'][((((number + 90) % 100) - 10) % 10) - 1] || 'th'
  }`;
}

ReactSimpleTable.propTypes = {
  onChange: PropTypes.func,
};
